<template>
  <div class="p-3 border-b-2 border-gray-300">
    <div>
      <BaseInput v-model="internalQuery" type="text" field_name="" :placeholder="$t('search_filter')" @input="updateQuery" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      internalQuery: "",
    };
  },
  methods: {
    updateQuery() {
      this.$emit("queryUpdated", this.internalQuery);
    },
  },
};
</script>

<template>
  <div class="w-full text-left flex justify-between mt-1 text-primary-500 px-2 py-2 text-sm font-bold cursor-pointer transition duration-200 bg-primary-100 hover:bg-primary-200 hover:text-white">
    <div class="text-left flex">
      <label class="relative inline-block w-12 h-6">
        <input type="checkbox" class="opacity-0 w-0 h-0" @change="audioControl" v-model="isAudioEnabled" />
        <span :class="isAudioEnabled ? 'bg-blue-500' : 'bg-gray-300'" class="slider block absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition duration-300 ease-in-out rounded-full"></span>
        <span :class="{ 'translate-x-6': isAudioEnabled }" class="dot absolute left-1 bottom-1 bg-white w-4 h-4 rounded-full transition duration-300 ease-in-out transform"></span>
      </label>
      <p class="text-xxs pl-2">{{ alarmSoundLabel }}</p>
      <audio ref="audio" :src="audioSrc"></audio>
    </div>
    <div class="flex">
      <div @click="muteAudio" v-if="isMute">
        <BaseIcon icon="volume-mute" class="mr-2" />
      </div>
      <div @click="muteAudio" v-else>
        <BaseIcon icon="volume-up" class="mr-2" />
      </div>
      <div @click="showSettingsModal">
        <BaseIcon icon="cog" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alarmSoundLabel: {
      type: String,
      required: true,
    },
    audioSrc: {
      type: String,
      required: true,
    },
    initialMuteState: {
      type: Boolean,
      default: false,
    },
    alarmDuration: {
      type: Number,
      default: 180, // Default 3 minutes
    },
  },
  data() {
    return {
      isAudioEnabled: this.$store.getters.getAlarmSoundState,
      isMute: this.initialMuteState,
      isAudioPlaying: false,
      audioPlayInterval: null,
      remainingDuration: this.alarmDuration,
    };
  },
  methods: {
    audioControl() {
      if (!this.isAudioEnabled) {
        if (this.isAudioPlaying) {
          this.stopAudio();
        }
      }
      this.$store.commit("SET_ALARM_SOUND_STATE", this.isAudioEnabled);
    },
    muteAudio() {
      this.isMute = !this.isMute;
      this.stopAudio();
    },
    playAudio() {
      if (this.isAudioEnabled && !this.isAudioPlaying && !this.isMute) {
        this.$refs.audio.play();
        this.isAudioPlaying = true;
        this.remainingDuration = this.$store.getters.getAlaramDuration;

        this.audioPlayInterval = setInterval(() => {
          this.remainingDuration -= 3; // Subtract the duration of the audio file (3 seconds)
          if (this.remainingDuration > 0) {
            this.$refs.audio.currentTime = 0; // Rewind the audio
            this.$refs.audio.play(); // Play again
          } else {
            this.stopAudio(); // Stop the audio when the duration is reached
          }
        }, 3000); // Repeat every 3 seconds (duration of the audio file)
      }
    },
    stopAudio() {
      if (this.audioPlayInterval) {
        clearInterval(this.audioPlayInterval);
        this.audioPlayInterval = null;
      }
      if (this.isAudioPlaying) {
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
        this.isAudioPlaying = false;
      }
    },
    handleSearch(newQuery) {
      this.searchQuery = newQuery;
    },
    showSettingsModal() {
      this.$modal.show("alarm-sound-setting");
    },
  },

  // ... other component options ...
};
</script>

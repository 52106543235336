<template>
  <modal name="alarm-sound-setting" class="modal-inner modal-update-addon" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
    <span class="close-button" @click="hide('alarm-sound-setting')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      {{ $t("alarm_modal_title") }}
    </div>
    <div class="modal-body">
      <div class="p-4">
        <label for="audioDuration" class="block mb-2 text-sm font-medium text-gray-900">
          {{ $t("alarm_modal_msg") }}
        </label>
        <input type="range" id="audioDuration" @change="setAlaramDuration()" v-model="audioDuration" min="1" max="30" class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
        <div class="text-center text-sm font-medium text-gray-900">{{ audioDuration }} {{ $t("alarm_duration_type") }}</div>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 600;

export default {
  props: {},
  data() {
    return {
      audioDuration: this.$store.state.alarmDuration,
      updateInfo: null,
      updateImage: null,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    setAlaramDuration() {
      this.$store.commit("SET_ALARM_DURATION", this.audioDuration);
    },
    hide(id) {
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
